import React, { useState } from "react";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import MessageBox from "../component/MessageBox";
import { formatSize } from "../common/function";

const materialLoad = -1;
function CompressReport() {
  const [message, setMessage] = useState(null);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(null);
  const [fromDate, setFromDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [toDate, setToDate] = useState(new Date().toISOString().slice(0, 10));
  const isLogin = secureLocalStorage.getItem("isLogin");
  const isAdmin = secureLocalStorage.getItem("isAdmin");
  const token = secureLocalStorage.getItem("token");

  const handleSearch = () => {
    if (!!isLogin && !!isAdmin) {
      setLoading(materialLoad);
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/admin/compress_log_report`,
          { fromDate, toDate },
          {
            headers: {
              JWTToken: token,
            },
          }
        )
        .then((response) => {
          setLoading(null);
          setList(response.data.data);
        })
        .catch((error) => {
          setLoading(null);
          setMessage({
            type: "error",
            message: error.message,
          });
        });
    }
  };

  if (!isAdmin || !isLogin)
    return <MessageBox type="error" message="You donot have permission" />;

  return (
    <>
      <Header />
      <div className="fluid-container m-3">
        <h3>Compress Log Report</h3>
        <div className="row mb-3">
          <div className="col-md-4">
            <label htmlFor="fromDate">Date From:</label>
            <input
              type="date"
              id="fromDate"
              className="form-control"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="toDate">Date To:</label>
            <input
              type="date"
              id="toDate"
              className="form-control"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
            />
          </div>
          <div className="col-md-4 d-flex align-items-end">
            <button className="btn btn-primary" onClick={handleSearch}>
              {loading === materialLoad ? <div className="loader" /> : "Search"}
            </button>
          </div>
        </div>

        {message && message.type && (
          <MessageBox type={message.type} message={message.message} />
        )}

        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>SN</th>
                <th>Item</th>
                <th>Resource</th>
                <th>Content</th>
                <th>File Id</th>
                <th>Original Size</th>
                <th>Compress Size</th>
                <th>Difference %</th>
                <th>Original</th>
                <th>Compress</th>
                <th>Created At</th>
                <th>Updated At</th>
              </tr>
            </thead>
            <tbody>
              {list.map((item, index) => (
                <tr key={item.ID}>
                  <td>{index + 1}</td>
                  <td>{item.ITEM}</td>
                  <td>{item.RESOURCE}</td>
                  <td>{item.CONTENT}</td>
                  <td>{item.FILE_ID}</td>
                  <td>{formatSize(item.ORIGINAL_SIZE)}</td>
                  <td>{formatSize(item.COMPRESSED_SIZE)}</td>
                  <td>{parseInt(item.DIFFERENCE_PERCENT)}%</td>
                  <td>
                    <a
                      href={item.ORIGINAL_LINK}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Link
                    </a>
                  </td>
                  <td>
                    <a
                      href={item.COMPRESSED_LINK}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Link
                    </a>
                  </td>
                  <td>{item.CREATED_AT}</td>
                  <td>{item.UPDATED_AT}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default CompressReport;
