import React, { useState } from "react";
import secureLocalStorage from "react-secure-storage";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { Navbar, Nav, Container, NavDropdown, Button } from "react-bootstrap";

function Header() {
  const isLogin = secureLocalStorage.getItem("isLogin");
  const isAdmin = secureLocalStorage.getItem("isAdmin");
  const name = secureLocalStorage.getItem("name");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      setLoading(true);
      const loginResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/logout`,
        {
          headers: {
            JWTToken: secureLocalStorage.getItem("token"),
          },
        }
      );
      setLoading(false);
      if (loginResponse.data.success) {
        secureLocalStorage.clear();
        navigate("/login");
      }
    } catch (error) {
      secureLocalStorage.clear();
      navigate("/login");
      setLoading(false);
    }
  };

  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand as={Link} to="/">
          <img
            src={`${process.env.PUBLIC_URL}/logo.png`}
            alt="Logo"
            height="30"
            className="d-inline-block align-top"
          />
          {process.env.REACT_APP_NAME}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarNav" />
        <Navbar.Collapse id="navbarNav">
          <Nav className="me-auto mb-2 mb-lg-0">
            <Nav.Link as={Link} to="/">
              Home
            </Nav.Link>
            {isLogin && isAdmin && (
              <>
                <Nav.Link as={Link} to="/books">
                  Books
                </Nav.Link>
                <Nav.Link as={Link} to="/compressReport">
                  Compress Report
                </Nav.Link>
              </>
            )}
            <Nav.Link as={Link} to="/about">
              About Us
            </Nav.Link>
          </Nav>
          <Nav className="ms-auto mb-2 mb-lg-0">
            {isLogin ? (
              <NavDropdown title={name} id="navbarDropdown">
                {loading ? (
                  <div className="loader" />
                ) : (
                  <Button
                    className="btn btn-danger w-100"
                    onClick={handleLogout}
                  >
                    Logout
                  </Button>
                )}
              </NavDropdown>
            ) : (
              <Button as={Link} to="/login" variant="success">
                Login
              </Button>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
