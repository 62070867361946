import React from "react";
import Header from "../layout/Header";
import Footer from "../layout/Footer";

function About() {
  return (
    <div>
      <Header />
      <div className="container-fluid bg-white">
        <div className="row justify-content-center bg-white py-5 px-2">
          <div className="col-12 col-md-8 bg-white p-4">
            <div className="bg-white p-3">
              <img
                src="https://emsglobal.ed-space.net/KeyDoc/Attachments/Comms/516/pearl_email_activation_back.png"
                alt="img"
                className="img-fluid"
              />
            </div>
            <div className="p-3 mt-4">
              <p className="mb-3">
                The Gowell Project is a non-profit initiative providing software
                tools to inspire and motivate individuals of different racial,
                religious, and ethnic groups to build a united and prosperous
                global community.
              </p>
              <p className="mb-3">
                Inspired by the Baha’i faith but independent of Baha’i
                institutions, the project is based on fostering an environment
                in which all people have equal chances to grow spiritually,
                physically, and intellectually and where unique beliefs and
                ideas are respected and cherished.
              </p>
              <p className="mb-5">
                “Through the mere revelation of the word “Fashioner,” issuing
                forth from His lips and proclaiming His attribute to mankind,
                such power is released as can generate, through successive ages,
                all the manifold arts which the hands of man can produce.”
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default About;
